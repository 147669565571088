



















































































































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import FieldUser from "@/views/components/Common/Filed.vue";
@Component({
  components: { FieldUser }
})
export default class HeaderResumeCard extends Vue {
  desktop = window.innerWidth > 600 ? true : false;
  @Prop()
  user!: Record<string, any>;
  migration(number: number): string {
    switch (number) {
      case 1:
        return "تحصیلی";
      case 2:
        return "کاری";
      case 3:
        return "سرمایه گذاری";
      case 4:
        return "سرچ";
      default:
        return "دیگر";
    }
  }
}
