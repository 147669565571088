




































































































































































































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import moment from "moment-jalaali";
import FieldUser from "@/views/components/Common/Filed.vue";
@Component({
  components: { FieldUser }
})
export default class InformationResume extends Vue {
  @Prop()
  user!: Record<string, any>;
  showLicense(number: any): string {
    switch (number) {
      case "1":
        return "دیپلم";
      case "2":
        return "لیسانس";
      case "3":
        return "دکتری";
      case "4":
        return "پست دکتری";
      case "5":
        return "فنی حرفه‌ای";
      case "6":
        return "---";
      case "7":
        return "---";
      case "8":
        return "---";
      case "9":
        return "دیگر";
      default:
        return "مدرک تحصیلی اشتباه است";
    }
  }
  showLanguage(number: number): string {
    switch (String(number)) {
      case "1":
        return "انگلیسی";
      case "2":
        return "فرانسوی";
      case "3":
        return "آلمانی";
      case "4":
        return "ایتالیایی";
      case "5":
        return "اسپانیایی";
      case "6":
        return "ترکی";
      case "7":
        return "روسی";
      case "8":
        return "عربی";
      case "9":
        return "دیگر";
      case "10":
        return "چینی";
      case "11":
        return "کره ای";
      case "12":
        return "ژاپنی";
      case "13":
        return "کردی";
      default:
        return "مدرک تحصیلی اشتباه است";
    }
  }
  language_level(level: string): string {
    switch (level) {
      case "a1":
        return "A1 - شروع";
      case "a2":
        return "A2 - ابتدایی";
      case "b1":
        return "B1 - متوسط به پایین";
      case "b2":
        return "B2 - متوسط";
      case "c1":
        return "C1 - متوسط به بالا";
      case "c2":
        return "C2 - پیشرفته";
      default:
        return "سطح اشتباه است";
    }
  }
  Shamsi(date: any) {
    return moment(date).format("jYYYY/jMM/jDD");
  }
}
