









import { Component, Vue } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";
import HeaderCardResume from "@/views/components/pages/Resume/HeaderResume.vue";
import InformationResumeCard from "@/views/components/pages/Resume/InformationResume.vue";
@Component({
  components: { HeaderCardResume, InformationResumeCard }
})
export default class General extends Vue {
  private baseUrl = process.env.VUE_APP_URL || "https://api.bms-go2tr.com";
  user: Record<string, any> = {};
  mounted() {
    this.View();
  }

  public async View(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().resume(
        this.$route.params.id
      );
      this.user = res.data.data;
    } catch (error:any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
}
